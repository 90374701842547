import { useNavigate } from "react-router-dom";
import { useFetchActiveProjectsByGoalId, useFetchCompletedProjectsByGoalId } from "@/queries/projects";
import { useMemo } from "react";

interface GoalItemProps {
  goal: {
    id: string;
    name: string;
    details: string;
    has360Assessment: boolean;
  };
}

export function GoalItem({ goal }: GoalItemProps) {
  const navigate = useNavigate();
  const {data: activeProjects } = useFetchActiveProjectsByGoalId(goal.id);
  const {data: completedProjects } = useFetchCompletedProjectsByGoalId(goal.id);

  const { numActiveProjects, numCompletedProjects } = useMemo(() => ({
    numActiveProjects: activeProjects?.length || 0,
    numCompletedProjects: completedProjects?.length || 0,
  }), [activeProjects, completedProjects]);
  
  return (
    <div
      key={goal.id}
      className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400 cursor-pointer"
      onClick={() => navigate(`/goals/${goal.id}`)}
    >
      <div className="min-w-0 flex-1">
        <span className="absolute inset-0" aria-hidden="true" />
        <p className="text-xl leading-8 font-medium text-gray-900">{goal.name}</p>
        <p className="truncate text-sm text-gray-500">{goal.details}</p>
        <div className="inline-flex flex-wrap gap-2 pt-2">
          <div>
            <span className="py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium bg-teal-100 text-teal-800 rounded-full dark:bg-teal-500/10 dark:text-teal-500">
              <svg
                className="flex-shrink-0 size-3"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.362 5.214A8.252 8.252 0 0 1 12 21 8.25 8.25 0 0 1 6.038 7.047 8.287 8.287 0 0 0 9 9.601a8.983 8.983 0 0 1 3.361-6.867 8.21 8.21 0 0 0 3 2.48Z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 18a3.75 3.75 0 0 0 .495-7.468 5.99 5.99 0 0 0-1.925 3.547 5.975 5.975 0 0 1-2.133-1.001A3.75 3.75 0 0 0 12 18Z"
                />
              </svg>
              Active: {numActiveProjects}
            </span>
          </div>

          <div>
            <span className="py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium bg-sky-100 text-sky-800 rounded-full dark:bg-red-500/10 dark:text-sky-500">
              <svg
                className="flex-shrink-0 size-3"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
              Completed: {numCompletedProjects}
            </span>
          </div>

          {goal.has360Assessment && (
            <div>
              <span className="py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium bg-purple-100 text-purple-800 rounded-full dark:bg-purple-500/10 dark:text-purple-500">
                <svg
                  className="flex-shrink-0 size-3"
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="1"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M7 1H3a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4L7 1z" />
                  <polyline points="7 1 7 4 10 4" />
                  <line x1="4" y1="6" x2="8" y2="6" />
                  <line x1="4" y1="8" x2="8" y2="8" />
                </svg>
                Has 360 Report
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
