import React from "react";
import { Collapsible, CollapsibleTrigger, CollapsibleContent } from "@shadcn/collapsible";
import Markdown from 'markdown-to-jsx'
import { PromptResponse } from "@shared/types";
import { ChevronDownIcon, ChevronUpIcon } from "lucide-react";
import { AddGoalButton } from "./AddGoalButton";
import { motion, AnimatePresence } from "framer-motion";
import "@/styles/md-container.css";

interface ReportSectionProps {
  response: PromptResponse;
}

export const ReportSection: React.FC<ReportSectionProps> = ({ response }) => {
  const [open, setOpen] = React.useState(false);

  const markdownOptions = {
    overrides: {
      AddGoalButton: {
        component: AddGoalButton,
        props: {
          addedByResponseId: response.id,
        },
      },
    },
  }
  
  return (
    <AnimatePresence mode="wait">
      <motion.div
        key={response.id}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.3 }}
      >
        <Collapsible
          open={open}
          onOpenChange={setOpen}
          className="rounded-lg border border-gray-200 bg-white shadow-sm dark:border-gray-800 dark:bg-gray-950"
        >
          <CollapsibleTrigger asChild>
            <div className="flex items-center justify-between px-4 py-3 cursor-pointer">
              <h2 className="text-lg font-medium text-gray-900 dark:text-gray-100">{response.title}</h2>
              <button className="flex items-center gap-2">
                {open ? (
                  <ChevronUpIcon className="h-5 w-5 text-gray-500 dark:text-gray-400" />
                ) : (
                  <ChevronDownIcon className="h-5 w-5 text-gray-500 dark:text-gray-400" />
                )}
              </button>
            </div>
          </CollapsibleTrigger>
          <CollapsibleContent
            className="px-4 pb-4 overflow-hidden"
          >
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              <Markdown className="md-container text-gray-500 dark:text-gray-400" options={markdownOptions}>
                {response.answer}
              </Markdown>
            </motion.div>
          </CollapsibleContent>
        </Collapsible>
      </motion.div>
    </AnimatePresence>
  );
};
