import { AlertCircle } from "lucide-react";
import React, { useCallback, useMemo, useEffect } from "react";
import { Offline } from "react-detect-offline";
import { Panel, PanelGroup } from "react-resizable-panels";
import { classNames } from "@/web/lib/utils";

import { Alert, AlertDescription, AlertTitle } from "@shadcn/alert";
import { Toaster } from "@shadcn/toaster";

import { useChatContext } from "@/web/hooks/useChatContext";
import ChatWindow from "../pages/Chat/ChatWindow";
import { Navbar } from "./Navbar";

type MainContainerProps = {
  children: React.ReactNode;
};

function OfflineAlertBox() {
  return (
    <Alert className="container mt-4 mb-4" variant="destructive">
      <AlertCircle className="h-4 w-4" />
      <AlertTitle>You have no connection</AlertTitle>
      <AlertDescription>
        Your data may not be saved. Please check your connection.
      </AlertDescription>
    </Alert>
  );
}

const MainContainer = ({ children }: MainContainerProps) => {
  const { isChatVisible, setIsChatVisible } = useChatContext();

  const handleClose = useCallback(() => {
    setIsChatVisible(false);
  }, [setIsChatVisible]);

  useEffect(() => {
    const navbar = document.querySelector('nav');
    if (navbar) {
      if (isChatVisible && window.innerWidth < 768) {
        navbar.style.display = 'none';
      } else {
        navbar.style.display = 'block';
      }
    }
  }, [isChatVisible]);

  const chatWindowContent = useMemo(
    () => (
      <div className="h-full">
        <ChatWindow onClose={handleClose} />
      </div>
    ),
    [handleClose]
  );

  return (
    <div className="flex h-[100dvh] flex-col">
      <Toaster />
      <Navbar />
      <PanelGroup direction="horizontal" className="flex-1">
        <Panel
          defaultSize={100}
          minSize={60}
          className="transition-[flex-basis] duration-300 ease-in-out"
        >
          <div className="h-full overflow-y-auto">
            <div className="mx-auto w-full max-w-[1000px] space-y-8 md:p-4">
              <Offline>
                <OfflineAlertBox />
              </Offline>
              {children}
            </div>
          </div>
        </Panel>
        <Panel
          defaultSize={0}
          minSize={30}
          collapsible={true}
          className={classNames(
            "transition-[flex-basis] duration-300 ease-in-out",
            isChatVisible ? "md:relative fixed inset-0 z-50 md:z-auto" : ""
          )}
          style={
            {
              "--panel-size": isChatVisible ? "500px" : "0px",
              flexBasis: "var(--panel-size)",
            } as React.CSSProperties
          }
        >
          <div className="h-full border-l bg-white">{chatWindowContent}</div>
        </Panel>
      </PanelGroup>
    </div>
  );
};

export default MainContainer;
