import { useEffect, useState } from "react";
import { useAddProjectMutation } from "@/queries/projects";
import { Button } from "@shadcn/button";
import WarningBox from "../../components/WarningBox";
import { useAuthContext } from "@/providers/AuthProvider";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { createHeadlessEditor } from "@lexical/headless";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { ListItemNode, ListNode } from "@lexical/list";
import { $convertFromMarkdownString, TRANSFORMERS } from "@lexical/markdown";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { Check, Plus } from "lucide-react";
import { useFetchAiProjectRecomendations } from "../../../queries/ai";
import { AiWaitingIndicator } from "./AiWaitingIndicator";

interface SuggestProjectResultsProps {
  userInput: string;
  goalId?: string;
}

interface ProjectRecommendation {
  project: string;
  description: string;
  details: string;
}

export default function SuggestProjectResults({ userInput, goalId }: SuggestProjectResultsProps) {
  const addProject = useAddProjectMutation();

  const { data, error, isPending } = useFetchAiProjectRecomendations(userInput);

  const [addedProjects, setAddedProjects] = useState<Record<string, boolean>>({});
  const [selectedProjectName, setSelectedProjectName] = useState("");
  const [selectedProjectDetails, setSelectedProjectDetails] = useState("");
  const [hasProjectChanged, setHasProjectChanged] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { user } = useAuthContext();
  
  const userId = user?.uid || "";

  const [projects, setProjects] = useState<ProjectRecommendation[]>([]);


  const editor = createHeadlessEditor({
    nodes: [
      HeadingNode,
      ListNode,
      ListItemNode,
      QuoteNode,
      CodeNode,
      CodeHighlightNode,
      TableNode,
      TableCellNode,
      TableRowNode,
      AutoLinkNode,
      LinkNode,
    ],
    onError: (error) => {
      console.log("error create headless editor", error);
    },
  });
  
  useEffect(() => {
    // This requires the update listener to actually update the project when the editor is changed.
    if (hasProjectChanged) {
      editor.update(() => {
        $convertFromMarkdownString(selectedProjectDetails, TRANSFORMERS);
      });
    }
  }, [hasProjectChanged, selectedProjectDetails, editor]);

  useEffect(() => {
    console.log("data", data);
    if (data?.recommendations) {
      setProjects(data.recommendations);
    }
  }, [data]);

  if (userInput == "") {
    return <div>empty</div>;
  }

  if (error) {
    setErrorMessage("Error loading project recommendations. Please try again later.");
  }

  if (isPending) {
    return <AiWaitingIndicator />;
  }

  editor.registerUpdateListener(({ editorState }) => {
    editorState.read(() => {
      if (hasProjectChanged) {
        const details = JSON.stringify(editor.getEditorState().toJSON());
        console.log(selectedProjectName, details);
        addProject.mutate({
          name: selectedProjectName,
          details: details,
          goalId: goalId,
          userId,
        });
        setAddedProjects((prev) => ({ ...prev, [selectedProjectName]: true }));
        setHasProjectChanged(false); // Reset the flag after processing the change
      }
    });
  });

  const handleAddProject = (projectName: string, details: string) => {
    setSelectedProjectName(projectName);
    setSelectedProjectDetails(details);
    setHasProjectChanged(true); // Set the flag to indicate that the recommend project has been added
  };

  return (
    <>
      {projects.map((project, index) => (
        <div
          key={index}
          className="flex justify-between items-center p-6 rounded-md border border-gray-200 mb-1 hover:shadow-lg transition-shadow"
        >
          <div className="pr-6">
            <h2 className="text-lg font-bold mb-1">{project.project}</h2>
            <p className="text-slate-500">{project.description}</p>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {addedProjects[project.project] ? (
              <Button disabled>
                <Check size={16} className="inline mr-1" /> Added
              </Button>
            ) : (
              <Button type="button" onClick={() => handleAddProject(project.project, project.details)}>
                <Plus size={16} className="inline mr-1" /> Add
              </Button>
            )}
          </div>
        </div>
      ))}
      {errorMessage && <WarningBox errorMessage={errorMessage} />}
    </>
  );
}
