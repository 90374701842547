import React, { useState } from "react";
import { Button } from "@shadcn/button";
import {
  DialogDescription,
  DialogFooter,
  DialogContent,
  DialogHeader,
  DialogTrigger,
  DialogTitle,
  Dialog,
} from "@shadcn/dialog";
import { usePostHog } from "posthog-js/react";
import { useFetchGoals } from "../../../queries/goals";
import SuggestProjectResults from "./SuggestProjectResults";

interface SuggestProjectButtonDialogProps {
  children: React.ReactNode;
  goalId?: string;
}

export function SuggestProjectButtonDialog({ children, goalId }: SuggestProjectButtonDialogProps) {
  const [userInput, setUserInput] = useState<string>("");
  const [showResults, setShowResults] = useState(false);
  const [open, setOpen] = useState(false);

  const effectiveGoalId = goalId;

  const posthog = usePostHog();

  const { data: goals } = useFetchGoals();
  if (!goals) return null;
  const goal = goals.find((goal) => goal.id === effectiveGoalId);

  const goalName = goal ? goal.name : "No Active Goal";

  const getProjects = () => {
    posthog?.capture("app_ai_executed", { command_type: "suggest_project" });
    setShowResults(true);
    let details = goal?.details;
    if (details && details.length > 500) {
      details = details.substring(0, 500) + "..."; // truncate and append "..." for clarity
    }
    setUserInput("Goal Name: " + goal?.name + "\nGoal Description: " + details || "");
  };

  const resetView = () => {
    setShowResults(false);
    setUserInput("");
  };

  return (
    <div className="sm:max-w-[800px] max-h-[70vh] overflow-auto">
      <Dialog
        open={open}
        onOpenChange={(state) => {
          if (!state) resetView();
          setOpen(state);
        }}
      >
        <DialogTrigger asChild>{children}</DialogTrigger>
        <DialogContent className="sm:max-w-[800px] max-h-[90vh] overflow-auto">
          <DialogHeader>
            <DialogTitle className="text-2xl">New Initiative By AI Coach</DialogTitle>
          </DialogHeader>
          <DialogDescription>
            <p className="text-base leading-normal p-4">
              Our A.I. Copilot takes care of analyzing your Northstar goal for you. It then generates a customized list
              of the most relevant Initiatives that offer a high chance of progress:
            </p>
            <div className="text-base pt-4 pb-4 pl-8 pr-8">
              <p className="font-bold text-slate-700">{goalName}</p>
              <p className="text-slate-700">
                {goal &&
                  goal.details &&
                  goal.details.split("\n").map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
              </p>
            </div>
            <p className="text-base leading-normal p-4">
              For even better-fit suggestions, simply fine-tune your goal and provide more detailed descriptions, and
              our A.I. will tailor the recommendations to your preferences.
            </p>
          </DialogDescription>
          <DialogFooter>
            <Button type="submit" onClick={getProjects} className="w-full text-lg">
              Suggest
            </Button>
          </DialogFooter>
          {showResults && userInput !== "" && (
            <SuggestProjectResults 
              userInput={userInput} 
              goalId={effectiveGoalId}
            />
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
