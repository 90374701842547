import { useState } from "react";
import { useNavigate } from "react-router-dom";
import prompts from "./prompts.json";
import { JsonPrompts, Prompt } from "@shared/types";
import { motion } from "framer-motion";
import { useKeypress } from "@/hooks/useKeypress";
import { Back } from "@components/Back";

// const promptsData: {
//   [key: string]: Prompt;
// } = prompts;

export function PromptLibrary() {
  const [, setSelectedPrompt] = useState<Prompt>();
  const [selectedCategory, setSelectedCategory] = useState<string>("All");

  const navigate = useNavigate();

  const handleSelect = (key: string, prompt: Prompt) => {
    setSelectedPrompt(prompt);
    navigate(`/prompts/new/${key}`);
  };

  const promptsData = prompts as JsonPrompts;
  const promptsKey = Object.keys(promptsData);
  const categories = ["All", ...new Set(Object.values(promptsData).map((prompt) => prompt.category))];

  const categoryEmojis: { [category: string]: string } = {
    All: "📚",
    Audit: "🔍",
    "Problem Solving": "🧩",
    "Daily Prompt": "✍️",
    Reflection: "🤔",
    Life: "🌱",
    Productivity: "⏳",
  };

  const handleCategorySelect = (category: string) => {
    setSelectedCategory(category);
  };
  const handleCloseButton = () => {
    navigate(-1);
  };

  useKeypress("Escape", handleCloseButton);

  return (
    <div className="container">
      <Back link="/prompts" text="Your Prompts" className="pt-6 pb-10" />
      <div className="flex flex-wrap mb-4">
        {categories.map((category, index) => (
          <button
            key={index}
            className={`text-sm px-4 py-2 m-1 rounded-full ${
              selectedCategory === category ? "bg-primary text-white" : "bg-gray-100 text-gray-800"
            }`}
            onClick={() => handleCategorySelect(category)}
          >
            {categoryEmojis[category] || "❓"} {category}
          </button>
        ))}
      </div>
      <motion.div initial={{ y: "-10vw", opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.3 }}>
        <div className="grid grid-cols-1 gap-4 mt-6 lg:grid-cols-3">
          {promptsKey.map((key: string, index: number) => {
            const prompt = promptsData[key];
            if ((selectedCategory === "All" || prompt.category === selectedCategory) && !prompt.isDashboard) {
              return (
                <div
                  key={`${prompt.title}-${index}`}
                  className="p-8 border border-gray-300 max-w-[100%] cursor-pointer hover:shadow-lg rounded-lg"
                  onClick={() => handleSelect(key, prompt)}
                  onKeyUp={(event) => {
                    if (event.key === "Enter") {
                      handleSelect(key, prompt);
                    }
                  }}
                >
                  <span className="block mb-2 text-gray-400">{prompt.category}</span>
                  <h2 className="text-2xl mb-2 font-semibold">{prompt.title}</h2>
                  <p className="text-gray-500 line-clamp-7">{prompt.description}</p>
                </div>
              );
            }
            return null;
          })}
        </div>
      </motion.div>
    </div>
  );
}