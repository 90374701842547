import logo from "@/images/lifehack-logo-navbar.png";
import { classNames } from "@/web/lib/utils";
import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Button } from "@shadcn/button";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import UserFeedbackPopup from "../../components/UserFeedbackPopup";
import { ProfileDropdown } from "./ProfileDropdown";
import { useUpdateNavigationBar } from "./useUpdateNavigationBar";
import { Notification } from "./Notification";

export function Navbar() {
  const [showFeedbackPopup, setShowFeedbackPopup] = useState(false);

  const initialNavigation = [
    { name: "Dashboard", href: "/", current: false },
    { name: "Growth Prompts", href: "/prompts", current: false },
    { name: "Northstars", href: "/northstars", current: false },
    { name: "Learn", href: "https://learn.lifehack.org/", current: false },
  ];

  const navigation = useUpdateNavigationBar(initialNavigation);

  return (
    <Disclosure as="nav" className="bg-primary sticky top-0 z-50">
      {({ open }) => (
        <>
          <div className="mx-auto w-full px-4 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex flex-shrink-0 items-center">
                  <NavLink to="/" className="hidden sm:block">
                    <img className="h-8 w-auto" src={logo} alt="Lifehack" />
                  </NavLink>
                </div>
                <div className="hidden sm:ml-6 sm:block">
                  <div className="flex space-x-4">
                    {navigation.map((item) => (
                      <NavLink
                        key={item.name}
                        className={({ isActive }: { isActive: boolean }) =>
                          classNames(
                            isActive
                              ? "bg-[#204B40] text-white"
                              : "text-gray-300 hover:bg-[#15322A] hover:text-white",
                            "rounded-md px-3 py-2 text-sm font-medium"
                          )
                        }
                        to={item.onClick ? "#" : item.href}
                        onClick={(e) => {
                          if (item.onClick) {
                            e.preventDefault();
                            item.onClick();
                          }
                        }}
                      >
                        {item.name}
                      </NavLink>
                    ))}
                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                <Button
                  className="bg-white text-slate-900 rounded-full hover:bg-slate-300 h-8 px-3 text-sm mr-2"
                  onClick={() => setShowFeedbackPopup(true)}
                >
                  Feedback
                </Button>
                <Notification />
                <ProfileDropdown />
              </div>
            </div>
          </div>
          {showFeedbackPopup && (
            <UserFeedbackPopup onClose={() => setShowFeedbackPopup(false)} />
          )}

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as={NavLink}
                  to={item.onClick ? "#" : item.href}
                  className={({ isActive }: { isActive: boolean }) =>
                    classNames(
                      isActive
                        ? "bg-gray-900 text-white"
                        : "text-gray-300 hover:bg-gray-700 hover:text-white",
                      "block rounded-md px-3 py-2 text-base font-medium"
                    )
                  }
                  onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                    if (item.onClick) {
                      e.preventDefault();
                      item.onClick();
                    }
                  }}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
