import { Sparkles, SendHorizontal, MessageCircleQuestion } from "lucide-react";
import { useChatContext } from "@/web/hooks/useChatContext";
import { useCallback } from "react";
import { posthog } from 'posthog-js';

function AskCoach({ goalId }: { goalId: string | undefined }) {
  const { isChatVisible, setIsChatVisible, append, setGoalId, setChatType } = useChatContext();

  const handleSelectCard = useCallback(
    async (goal: string) => {
      // Set chat type based on the message
      if (goal === "Let's start a Coaching Session") {
        setChatType("coachsession");
      } else {
        setChatType("dashboard");
      }

      if (goalId) {
        setGoalId(goalId);
      }
      if (!isChatVisible) {
        setIsChatVisible(true);
      }
      if (append) {
        await append({ role: 'user', content: goal });
      }
    },
    [isChatVisible, setIsChatVisible, append, setGoalId, goalId, setChatType]
  );

  const cards = [
    "Let's start a Coaching Session",
    "What's the most impactful thing I can do today for my Big Northstar?",
    "Can you remind me why I chose this Big Northstar in the first place?",
    "I'm feeling stuck. How can I break down my Big Northstar into smaller steps?",
    "What's one habit I could start that would significantly impact my Big Northstar?",
    "I’m feeling overwhelmed. What’s the one thing I should focus on right now to move closer to my Big Northstar?",
  ];

  return (
    <>
      <div className="relative mt-16">
        <input
          type="text" 
          placeholder="Ask me anything about your Big Northstar..."
          className="w-full p-4 pr-12 border rounded-lg focus:outline-none focus:ring-2 focus:ring-primary/20 h-14"
          onKeyDown={(e) => {
            if (e.key === 'Enter' && e.currentTarget.value) {
              handleSelectCard(e.currentTarget.value);
              e.currentTarget.value = '';
            }
          }}
        />
        <button 
          className="absolute right-2 top-1/2 -translate-y-1/2 p-2 bg-primary text-white rounded-lg hover:bg-primary/90 transition-colors"
          onClick={() => {
            const input = document.querySelector('input') as HTMLInputElement;
            if (input.value) {
              handleSelectCard(input.value);
              input.value = '';
            }
          }}
        >
          <SendHorizontal className="w-5 h-5" />
        </button>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 my-4">
        {cards.map((goal, index) => {
          if (goal === "Let's start a Coaching Session" && !posthog.isFeatureEnabled('coaching-session')) {
            return null;
          }
          return (
            <div
              key={index}
              className={`p-4 border rounded-lg shadow-sm hover:shadow-md flex items-center cursor-pointer ${
                goal === "Let's start a Coaching Session" ? "bg-brand-accent-purple text-white" : ""
              }`}
              onClick={() => handleSelectCard(goal)}
            >
              {goal === "Let's start a Coaching Session" ? (
                <MessageCircleQuestion className={`${goal === "Let's start a Coaching Session" ? "text-white" : "text-slate-400"} w-5 h-5 shrink-0 mr-3`} />
              ) : (
                <Sparkles className="text-slate-400 w-5 h-5 shrink-0 mr-3" />
              )}
              <span className="flex-1">{goal}</span>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default AskCoach;
