/**
 * v0 by Vercel.
 * @see https://v0.dev/t/fzQG1adgoZw
 * Documentation: https://v0.dev/docs#integrating-generated-code-into-your-nextjs-app
 */
import { PromptAnswer } from "@shared/types";
import { ReportSection } from "./ReportSection";
import "@/styles/md-container.css";
// import { Link } from "react-router-dom";
// import {
//   AlertDialog,
//   AlertDialogAction,
//   AlertDialogCancel,
//   AlertDialogContent,
//   AlertDialogDescription,
//   AlertDialogFooter,
//   AlertDialogHeader,
//   AlertDialogTitle,
// } from "@shadcn/alert-dialog";
// import { Button } from "@shadcn/button";
import { Card, CardContent, CardFooter, CardHeader } from "@shadcn/card";
// import {
//   HoverCard,
//   HoverCardContent,
//   HoverCardTrigger,
// } from "@shadcn/hover-card";
// import { ListRestart } from "lucide-react";
// import { useState } from "react";

interface DashboardReportProps {
  prompt: PromptAnswer;
}

export default function DashboardReport({ prompt }: DashboardReportProps) {
  const firstUpdatedAt = prompt.responses?.[0]?.updatedAt;

  return (
    <Card className="mt-14">
      <CardHeader>
        <h1 className="mt-10 text-3xl pb-2 font-bold tracking-tight text-primary dark:text-gray-100">
          Your Lifehack 360 Report
        </h1>
        {firstUpdatedAt && (
          <p className="text-md text-muted-foreground">
            Generated on {new Date(firstUpdatedAt.toDate()).toLocaleString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </p>
        )}
      </CardHeader>
      <CardContent className="mt-4 space-y-6">
        {/* Filter responses to only include those with responseTypeId starting with 'dashboard' and sort them by responseTypeId */}
        {prompt.responses
          ?.filter((response) => response.responseTypeId.startsWith("dashboard"))
          .sort((a, b) => a.responseTypeId.localeCompare(b.responseTypeId))
          .map((response) => (
            <ReportSection key={response.id} response={response} />
          ))}
      </CardContent>

      <CardFooter className="flex flex-col gap-4">
        <p className="text-sm text-slate-500 mt-2">
          This report is generated using AI technology and should be used as a
          general guide only. While we strive for accuracy, AI may make
          mistakes. You are responsible for verifying information and making
          your own decisions. Any actions taken based on this report are at your
          own discretion and risk.
        </p>
      </CardFooter>
    </Card>
  );
}
