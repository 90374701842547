import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  updateDoc,
  where,
  limit,
} from "firebase/firestore";
import { initializeFirestore, initializeAuth } from "../utils/firebaseConfig";
import { Goal, GoalProjects, Project } from "@shared/types";
import { captureException } from "../utils/logging";

export {
  useFetchGoals,
  useFetchGoalByGoalId,
  useFetchActiveGoalsWithProjects,
  useFetchGoalByProjectId,
};

type GoalWithAssessment = Goal & {
  has360Assessment: boolean;
};

interface GoalWithPrompt extends Goal {
  linkedPromptId?: string;
  linkedPromptName?: string;
}

const useFetchGoals = () => {
  return useQuery<GoalWithAssessment[], Error>({
    queryKey: ["goals"],
    queryFn: async () => {
      const db = initializeFirestore();
      const auth = initializeAuth();
      if (!auth.currentUser) throw new Error("No user is currently signed in");
      const uid = auth.currentUser.uid;

      try {
        // Get goals and assessments in parallel
        const [goalsSnapshot, assessmentsSnapshot] = await Promise.all([
          getDocs(
            query(
              collection(db, "goals"),
              where("userId", "==", uid),
              where("status", "==", "active"),
              orderBy("name")
            )
          ),
          getDocs(query(collection(db, "users", uid, "promptAnswers"))),
        ]);

        // Create a Set of goalIds that have assessments for O(1) lookup
        const goalsWithAssessment = new Set(
          assessmentsSnapshot.docs.map((doc) => doc.data().goalId)
        );

        // Map goals with assessment status
        return goalsSnapshot.docs.map((doc) => ({
          ...(doc.data() as Goal),
          id: doc.id,
          has360Assessment: goalsWithAssessment.has(doc.id),
        }));
      } catch (error) {
        captureException(
          "error fetching goals with assessments",
          error as Error
        );
        throw error;
      }
    },
  });
};

const useFetchGoalByGoalId = (goalId: string | undefined) => {
  return useQuery<GoalWithPrompt, Error>({
    queryKey: ["goals", goalId],
    queryFn: async () => {
      const goal = await fetchGoal(goalId);
      return goal as GoalWithPrompt;
    },
    enabled: !!goalId,
  });
};

const useFetchActiveGoalsWithProjects = () => {
  return useQuery<GoalProjects[], Error>({
    queryKey: ["goals", "projects"],
    queryFn: async () => fetchActiveGoalsWithProjects(),
  });
};

const useFetchGoalByProjectId = (projectId: string | undefined) => {
  return useQuery<Goal, Error>({
    queryKey: ["goals", "byProject", projectId],
    queryFn: async () => fetchGoalByProjectId(projectId),
    enabled: !!projectId,
  });
};

export function useUpdateGoalMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (goal: Goal) => {
      await updateGoal(goal.id, goal);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["goals"] });
    },
  });
}
export function useArchiveGoalMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (goalId: string) => {
      await updateGoal(goalId, { status: "archived" });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["goals"] });
    },
  });
}

export function useAddGoalMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (goal: Partial<Goal>) => {
      return await addGoal(goal);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["goals"] });
    },
  });
}

async function addGoal(goal: Partial<Goal>) {
  const { name, details, ...restOfGoal } = goal;
  if (!name) throw new Error("Goal name is required");

  try {
    const db = initializeFirestore();
    const auth = initializeAuth();
    if (!auth.currentUser) throw new Error("No user is currently signed in");
    const uid = auth.currentUser.uid;

    const goalCollectionRef = collection(db, "goals");
    const newGoal = await addDoc(goalCollectionRef, {
      name,
      details: details ?? "",
      userId: uid,
      status: "active",
      ...restOfGoal,
    });
    return { id: newGoal.id };
  } catch (error) {
    captureException("error adding goals", error as Error);
    throw error;
  }
}

async function updateGoal(goalId: string, fieldsToUpdate: Partial<Goal>) {
  try {
    const db = initializeFirestore();
    const goalRef = doc(db, "goals", goalId);
    await updateDoc(goalRef, fieldsToUpdate);
  } catch (error) {
    captureException("error updating goal", error as Error);
    throw error;
  }
}

async function fetchGoal(goalId: string | undefined) {
  if (!goalId) throw new Error("Goal ID is required");

  try {
    const db = initializeFirestore();
    const auth = initializeAuth();
    if (!auth.currentUser) throw new Error("No user is currently signed in");
    const uid = auth.currentUser.uid;

    // Run both queries in parallel
    const [goalDoc, assessmentSnapshot] = await Promise.all([
      getDoc(doc(db, "goals", goalId)),
      getDocs(
        query(
          collection(db, "users", uid, "promptAnswers"),
          where("goalId", "==", goalId),
          // Limit to 1 since we only need to know if it exists
          orderBy("createdAt", "desc"),
          limit(1)
        )
      )
    ]);

    if (!goalDoc.exists()) {
      throw new Error("Goal not found");
    }

    const goalData = goalDoc.data() as Goal;
    const assessment = assessmentSnapshot.docs[0];

    return {
      ...goalData,
      id: goalDoc.id,
      linkedPromptId: assessment?.id,
      linkedPromptName: assessment?.data().title,
    };
  } catch (error) {
    captureException("error fetching goal", error as Error);
    throw error;
  }
}

async function fetchGoalByProjectId(projectId: string | undefined) {
  if (!projectId) throw new Error("Project ID is required");

  try {
    const db = initializeFirestore();
    const projectRef = doc(db, "projects", projectId);
    const projectDoc = await getDoc(projectRef);

    if (!projectDoc.exists()) {
      throw new Error("Project not found");
    }

    const projectData = projectDoc.data() as Project;
    const goalId = projectData.goalId;

    if (!goalId) {
      throw new Error("Project is not associated with a goal");
    }

    return await fetchGoal(goalId);
  } catch (error) {
    captureException("error fetching goal by project id", error as Error);
    throw error;
  }
}

export const fetchActiveGoalsWithProjects = async (): Promise<
  GoalProjects[]
> => {
  try {
    const db = initializeFirestore();
    const auth = initializeAuth();
    if (!auth.currentUser) throw new Error("No user is currently signed in");
    const uid = auth.currentUser.uid;

    const goalsCollectionRef = collection(db, "goals");
    const querySnapshot = await getDocs(
      query(
        goalsCollectionRef,
        where("status", "==", "active"),
        where("userId", "==", uid),
        orderBy("name")
      )
    );

    const goals: GoalProjects[] = [];
    querySnapshot.forEach((doc) => {
      const goalData = doc.data() as GoalProjects;
      goals.push({
        ...goalData,
        id: doc.id,
        projects: [] as Project[], // Initialize projects array
      });
    });

    const projectsCollectionRef = collection(db, "projects");
    const querySnapshotProjects = await getDocs(
      query(
        projectsCollectionRef,
        where("completed", "==", false),
        where("userId", "==", uid),
        orderBy("name")
      )
    );

    querySnapshotProjects.forEach((doc) => {
      const projectData = doc.data() as Project;
      const goalId = projectData.goalId;
      const goal = goals.find((goal) => goal.id === goalId);
      if (goal) {
        goal.projects.push({
          ...projectData,
          id: doc.id,
        });
      }
    });

    return goals;
  } catch (error) {
    captureException(
      "Error fetching goals and projects from Firestore",
      error as Error
    );
    throw error;
  }
};
