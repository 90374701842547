import { useState } from "react";
import { Card, CardHeader, CardTitle } from "@shadcn/card";
import { useFetchGoals } from "@/queries/goals";
import { useAuthContext } from "@/providers/AuthProvider";
import { useFetchUserById } from "@/queries/user";
import { EditBigNorthstar } from "./EditBigNorthstar";
import { BigNorthStarEmpty } from "./BigNorthStarEmpty";
import { BigNorthstarContent } from "./BigNorthstarContent";
import { motion, AnimatePresence } from "framer-motion";

export function Initiatives() {
  const [isEditing, setIsEditing] = useState(false);
  const { user: currentUser } = useAuthContext();
  const { data: user } = useFetchUserById(currentUser?.uid || "");
  const { data: goals } = useFetchGoals();

  const handleEditClick = () => setIsEditing(true);

  if (goals?.length === 0) return null;
  if (!user?.bigGoalId && !isEditing) return <BigNorthStarEmpty onEdit={handleEditClick} />;

  return (
    <Card className="w-full relative group my-8">
      <CardHeader>
        <div className="flex justify-between items-center">
          <CardTitle className="text-3xl text-primary mt-8">Initiatives</CardTitle>
          {/* <CardDescription>
            {isEditing ? (
              <button onClick={() => setIsEditing(false)} className="text-primary font-medium underline">
                Cancel
              </button>
            ) : (
              <button onClick={handleEditClick} className="text-primary font-medium underline">
                Edit
              </button>
            )}
          </CardDescription> */}
        </div>
      </CardHeader>
      <AnimatePresence mode="wait">
        {isEditing ? (
          <motion.div
            key="edit"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <EditBigNorthstar
              goals={goals || []}
              currentGoalId={user?.bigGoalId || ""}
              onSave={() => setIsEditing(false)}
            />
          </motion.div>
        ) : (
          <motion.div
            key="content"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <BigNorthstarContent goalId={user?.bigGoalId || ""} />
          </motion.div>
        )}
      </AnimatePresence>
    </Card>
  );
}