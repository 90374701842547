import React, { useRef, useState } from "react";
import { Button } from "@shadcn/button";
import { Popover, PopoverContent, PopoverTrigger } from "@shadcn/popover";
import { Plus, Sparkles, Layers } from "lucide-react";
import { AddProjectButtonDialog } from "../pages/AddDialogs/AddProjectButtonDialog";
import { SuggestProjectButtonDialog } from "../pages/AddDialogs/SuggestProjectDialog";
import { useNavigate } from "react-router-dom";

interface CreateInitiativePopoverProps {
  goalId: string;
}

const CreateInitiativePopover: React.FC<CreateInitiativePopoverProps> = ({ goalId }) => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const navigate = useNavigate();

  const handleMouseEnter = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setPopoverOpen(true);
  };

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setPopoverOpen(false);
    }, 200);
  };

  const handleNavigateToLibrary = () => {
    navigate("/library");
  };

  return (
    <Popover open={popoverOpen} onOpenChange={setPopoverOpen}>
      <PopoverTrigger asChild>
        <Button variant="outline" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <Plus className="w-4 inline mr-1" /> Create Initiative
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-80" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <div className="grid gap-2">
          <div className="flex items-center gap-2 text-sm cursor-pointer">
            <AddProjectButtonDialog goalId={goalId}>
              <button className="flex items-center gap-2 text-sm cursor-pointer">
                <Plus className="w-4 inline" /> Add Manually
              </button>
            </AddProjectButtonDialog>
          </div>
          <SuggestProjectButtonDialog goalId={goalId}>
            <button className="flex items-center gap-2 text-sm cursor-pointer">
              <Sparkles className="w-4 inline" /> AI Assisted
            </button>
          </SuggestProjectButtonDialog>
          <div className="flex items-center gap-2 text-sm cursor-pointer" onClick={handleNavigateToLibrary}>
            <Layers className="w-4 inline" /> Add from templates
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default CreateInitiativePopover;