import { Link } from "react-router-dom";
import { Badge } from "@shadcn/badge";
import { Project } from "@shared/types";

interface InitiativeListProps {
  initiatives: Project[];
}

export function InitiativeList({ initiatives }: InitiativeListProps) {
  return (
    <>
      {initiatives.map((initiative) => (
        <Link
          key={initiative.id}
          to={`/projects/${initiative.id}`}
          className="block hover:bg-muted transition-colors duration-200"
        >
          <div className="flex items-center gap-4 py-2">
            <div className="mx-4">
              <div className="font-medium">{initiative.name}</div>
              <div className="flex items-center gap-2 text-xs">
                <Badge variant="default" className="bg-yellow-500 hover:bg-yellow-500/70 font-normal">
                  {initiative.numCompletedTasks ?? 0} completed
                </Badge>
                <Badge variant="outline" className="font-normal">
                  {initiative.numIncompleteTasks ?? 0} incomplete
                </Badge>
              </div>
            </div>
          </div>
        </Link>
      ))}
    </>
  );
}