import React, { ReactNode, Suspense, useEffect } from "react";
import { createBrowserRouter, Navigate, RouterProvider, useLocation } from "react-router-dom";
import { useChatContext } from "@/web/hooks/useChatContext";
import MainContainer from "./layouts/MainContainer";
import AccountPage from "./pages/AccountPage";
import GoalView from "./pages/GoalPage/GoalView";
import Login from "./pages/LoginPage/Login";
import Logoff from "./pages/LoginPage/Logoff";
import NotFoundPage from "./pages/NotFoundPage";
import ProjectView from "./pages/ProjectPage/ProjectView";
import PublicProjectView from "./pages/TemplatePage/PublicProjectView";
import TemplatesLibraryView from "./pages/TemplatePage/TemplatesLibraryView";
// import { ThankYouPage } from "./pages/CheckoutPage/ThankYouPage";
import { useAuthContext } from "@/providers/AuthProvider";
import { usePostHog } from "posthog-js/react";
import LoadingIndicatorFullPage from "./components/loading-indicator/LoadingIndicatorFullPage";
import { Dashboard } from "./pages/Dashboard";
import { ErrorBoundary } from "./pages/ErrorBoundary";
import { Northstar } from "./pages/Northstars";
import { CompletedPromptsPage } from "./pages/Prompts";
import { CreatePromptPage } from "./pages/Prompts/CreatePromptPage";
import { ExistingPromptPage } from "./pages/Prompts/ExistingPromptPage";
import { PromptLibrary } from "./pages/Prompts/PromptLibrary";
import { useLogUserActivity } from "@/queries/user";
import { Onboarding } from "./pages/Onboarding";
import { Paywall } from "./pages/Paywall";
interface ProtectedRouteProps {
  children: ReactNode;
}
const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const { user, loading } = useAuthContext();
  const location = useLocation();
  const posthog = usePostHog();

  useEffect(() => {
    document.title = "Lifehack App";
  }, [location]);

  useEffect(() => {
    if (location && user && posthog) {
      console.log("tracking pv", user, location.pathname);
      posthog.capture("$pageview");
    }
  }, [location, user, posthog]);

  if (loading) {
    return null; // or a loading spinner
  }

  return user ? <>{children}</> : <Navigate to="/login" state={{ from: location.pathname }} />;
};

// interface AdminRouteProps {
//   children: ReactNode;
// }

// const AdminRoute = ({ children }: AdminRouteProps) => {
//   const { user, isAdmin, loading } = useAuthContext();

//   if (loading) {
//     return null; // or a loading spinner
//   }

//   return user && isAdmin ? <>{children}</> : (<div>Error</div>);
// };

const createProtectedRoute = (path: string, element: ReactNode) => ({
  path,
  element: (
    <ProtectedRoute>
      <RouteLogger />
      <MainContainer>{element}</MainContainer>
    </ProtectedRoute>
  ),
  errorElement: <ErrorBoundary />,
});

// const createAdminRoute = (path: string, element: ReactNode) => ({
//   path,
//   element: (
//     <AdminRoute>{element}</AdminRoute>
//   ),
// });

const RouteLogger = () => {
  const { loading } = useAuthContext();
  const location = useLocation();
  const { mutate: logUserActivity } = useLogUserActivity();
  const lastLoggedPath = React.useRef<string | null>(null);
  const { setIsChatVisible } = useChatContext();

  useEffect(() => {
    if (!loading && location.pathname !== lastLoggedPath.current) {
      logUserActivity({ event_name: 'page_view', path: location.pathname });
      lastLoggedPath.current = location.pathname;
      
      // Dismiss chat when route changes
      setIsChatVisible(false);
    }
  }, [location, loading, logUserActivity, setIsChatVisible]);

  return null;
};

interface PublicRouteProps {
  children: ReactNode;
}

const PublicRoute = ({ children }: PublicRouteProps) => {
  const location = useLocation();
  const posthog = usePostHog();

  useEffect(() => {
    document.title = "Lifehack App";
  }, [location]);

  useEffect(() => {
    if (location && posthog) {
      console.log("tracking pv", location.pathname);
      posthog.capture("$pageview");
    }
  }, [location, posthog]);

  return <>{children}</>;
};

const createPublicRoute = (path: string, element: ReactNode) => ({
  path,
  element: (
    <PublicRoute>
      {element}
    </PublicRoute>
  ),
  errorElement: <ErrorBoundary />,
});

const AppRouting = () => {
  const { loading } = useAuthContext();

  if (loading) {
    return null;
  }

  const CheckoutPage = React.lazy(() => import('./pages/Paywall/CheckoutPage').then(module => ({ default: module.CheckoutPage })));
  // const Admin = React.lazy(() => import('./pages/Admin').then(module => ({ default: module.Admin })));
  const AccountCreation = React.lazy(() => import('./pages/PasswordSetup/AccountCreation').then(module => ({ default: module.AccountCreation })));
  const ForgotPassword = React.lazy(() => import('./pages/PasswordSetup/ForgotPassword').then(module => ({ default: module.ForgotPassword })));
  
  const router = createBrowserRouter([
    createProtectedRoute("/", <Dashboard />),
    createProtectedRoute("/goals/:goalId", <GoalView />),
    createProtectedRoute("/logoff", <Logoff />),
    createProtectedRoute("/projects/:projectID", <ProjectView />),
    createProtectedRoute("/library", <TemplatesLibraryView />),
    createProtectedRoute("/account", <AccountPage />),
    createProtectedRoute("/prompts", <CompletedPromptsPage />),
    createProtectedRoute("/prompts/new", <PromptLibrary />),
    createProtectedRoute("/prompts/new/:promptID", <CreatePromptPage />),
    createProtectedRoute("/prompts/:promptAnswerId", <ExistingPromptPage />),
    createProtectedRoute("/northstars", <Northstar />),
    createProtectedRoute("/onboarding", <Onboarding />),
    createProtectedRoute("/create-big-northstar", <Onboarding mode="new-northstar" />),
    // createAdminRoute("/backoffice", <Suspense fallback={<LoadingIndicatorFullPage />}><Admin /></Suspense>),
    createPublicRoute("/login", <Login />),
    createPublicRoute("/welcome", <Suspense fallback={<LoadingIndicatorFullPage />}><AccountCreation /></Suspense>),
    createPublicRoute("/checkout", <Suspense fallback={<LoadingIndicatorFullPage />}><CheckoutPage /></Suspense>),
    createPublicRoute("/forgot-password", <Suspense fallback={<LoadingIndicatorFullPage />}><ForgotPassword /></Suspense>),
    createPublicRoute("/share/:shareHash", <PublicProjectView />),
    createPublicRoute("/start", <Paywall />),
    createPublicRoute("/start/step1", <Paywall />),
    createPublicRoute("/start/step2", <Paywall />),
    createPublicRoute("/start/step3", <Paywall />),
    createPublicRoute("/start/step4", <Paywall />),
    createPublicRoute("/start/step4a", <Paywall />),
    createProtectedRoute("/start/step5", <Paywall />),
    createPublicRoute("*", <NotFoundPage />),
  ]);

  return <RouterProvider router={router} />;
};

export default AppRouting;