import { useState, useEffect } from "react";
import { Button } from "@shadcn/button";
import { PromptAnswer } from "@shared/types";
import { Back } from "@components/Back";
import { AiSection } from "./AiCoach/AiSection";
import LinkedGoal from "./LinkedGoal";
import { useFetchGoalByGoalId } from "@/queries/goals";
import { Card, CardContent, CardFooter, CardHeader } from "@shadcn/card";
import { ReportSection } from "@/web/pages/Dashboard/ReportSection";

interface DashboardReportProps {
  form: PromptAnswer;
}

const DashboardReport = ({ form }: DashboardReportProps) => {
  // const firstUpdatedAt = form.responses?.[0]?.updatedAt;

  if (!form.responses?.some(response => response.responseTypeId.startsWith("dashboard"))) {
    return null;
  }

  return (
    <Card className="mt-14">
      <CardHeader>
        <h1 className="mt-4 text-3xl pb-2 font-bold tracking-tight text-primary dark:text-gray-100">
          Lifehack 360 Report
        </h1>
      </CardHeader>
      <CardContent className="mt-2 space-y-6">
        {form.responses
          ?.filter((response) => response.responseTypeId.startsWith("dashboard"))
          .sort((a, b) => a.responseTypeId.localeCompare(b.responseTypeId))
          .map((response) => (
            <ReportSection key={response.id || response.responseTypeId} response={response} />
          ))}
      </CardContent>
      <CardFooter className="flex flex-col gap-4">
        <p className="text-sm text-slate-500 mt-2">
          This report is generated using AI technology and should be used as a
          general guide only. While we strive for accuracy, AI may make
          mistakes. You are responsible for verifying information and making
          your own decisions. Any actions taken based on this report are at your
          own discretion and risk.
        </p>
      </CardFooter>
    </Card>
  );
};

interface AnswerViewModeProps {
  form: PromptAnswer;
  handleEdit: () => void;
  toggleEditMode: () => void;
}

// TODO: add toggleEditMode back in, when the Ai Response need it to trigger edit mode
export const AnswerViewMode = ({ form, handleEdit }: AnswerViewModeProps) => {
  // Inside your component
  const [dateString, setDateString] = useState("");
  const { data: goal } = useFetchGoalByGoalId(form.goalId);
  const hasDashboardResponses = form.responses?.some(response => response.responseTypeId.startsWith("dashboard"));

  useEffect(() => {
    if (form.updatedAt) {
      const date: Date = form.updatedAt.toDate();
      const options: Intl.DateTimeFormatOptions = {
        weekday: "short",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      };
      setDateString(date.toLocaleString("en-US", options));
    }
  }, [form.updatedAt]);

  return (
    <div className="my-10">
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          <Back link="/prompts" text="Your Prompts" className="pt-4" />
        </div>
        {/* Hide Edit button for goal-linked prompts (implying Big Northstar) */}
        {!form.goalId && <Button onClick={handleEdit}>Edit</Button>}
      </div>
      <div className="flex justify-between items-center">
        <h1 className="text-4xl mt-2 mb-2 text-primary">{form.title}</h1>
        <span className="text-gray-400">{dateString}</span>
      </div>
      <p className="text-gray-500">{form.description}</p>
      {goal && <LinkedGoal name={goal.name} href={`/goals/${goal.id}`} />}
      {form.questions.map((question, index) => (
        <div key={`${question.question}-${index}`}>
          <h2 className="text-2xl mt-10 mb-1">{question.question}</h2>
          {question.answer.split("\n").map((line, index) => (
            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
            <p key={index}>{line}</p>
          ))}
        </div>
      ))}
      {hasDashboardResponses ? (
        <DashboardReport form={form} />
      ) : (
        <AiSection promptAnswer={form} />
      )}
      {/* <PromptAiCoach promptAnswer={form} toggleEditMode={toggleEditMode} /> */}
    </div>
  );
};
