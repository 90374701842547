import { useFetchActiveProjectsByGoalId } from "@/queries/projects";
import { CardContent } from "@shadcn/card";
import { InitiativeList } from "./InitiativeList";
import { NoProject } from "./NoProject";

interface BigNorthstarContentProps {
  goalId: string;
}

export function BigNorthstarContent({ goalId }: BigNorthstarContentProps) {
  const { data: latestInitiatives, isLoading, error } = useFetchActiveProjectsByGoalId(goalId);

  return (
    <CardContent className="grid gap-6">
      <div className="grid gap-4">
        {isLoading ? (
          <div>Loading latest initiatives...</div>
        ) : error ? (
          <div>Error loading initiatives.</div>
        ) : latestInitiatives && latestInitiatives.length > 0 ? (
          <InitiativeList initiatives={latestInitiatives} />
        ) : (
          <NoProject goalId={goalId} />
        )}
      </div>
      {latestInitiatives && latestInitiatives.length > 0 && (
        <div className="flex items-center text-sm text-muted-foreground">
          <div>
            {latestInitiatives[0]?.updatedAt
              ? "Latest activity: " + new Date(latestInitiatives[0].updatedAt).toLocaleDateString()
              : ""}
          </div>
        </div>
      )}
    </CardContent>
  );
}