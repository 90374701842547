import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import useSettingsStore from "@/store/settings";
import "@/styles/md-container.css"
import Markdown from "markdown-to-jsx";
import { cn } from "@shared/lib/utils";
import { ArrowUpRight, MoreHorizontal, Pencil, Trash, Newspaper } from "lucide-react";

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@shadcn/alert-dialog";

import { Button } from "@shadcn/button";
import { Input } from "@shadcn/input";
import { Textarea } from "@shadcn/textarea";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@shadcn/dropdown-menu";
import { useDocumentTitle } from "usehooks-ts";
import { useArchiveGoalMutation, useFetchGoalByGoalId, useUpdateGoalMutation } from "../../../queries/goals";

interface LinkedPromptProps {
  name: string;
  href: string;
}

function LinkedPrompt({ name, href }: LinkedPromptProps) {
  return (
    <div className="mt-8 mb-12 rounded-lg overflow-hidden shadow-sm">
      <div className="p-4 bg-gradient-to-br from-brand-secondary/60 to-brand-secondary/40">
        <h3 className="text-sm mb-2 flex items-center text-slate-500">
          <Newspaper className="mr-1 h-5 w-5 text-slate-500" />
          This North Star has a 360 Assessment & Report
        </h3>
        <a
          href={href}
          className="text-semibold inline-flex items-center text-primary hover:text-primary/80 transition-colors font-medium"
        >
          <span>{name}</span>
          <ArrowUpRight className="ml-1 h-4 w-4" />
        </a>
      </div>
    </div>
  );
}

interface GoalSectionProps {
  goalId: string;
}

const GoalSection: React.FC<GoalSectionProps> = ({ goalId }) => {
  const navigate = useNavigate();
  const { isPending, isError, data: goal, error } = useFetchGoalByGoalId(goalId);
  const updateGoal = useUpdateGoalMutation();
  const archiveGoal = useArchiveGoalMutation();

  const { updateSettings } = useSettingsStore();

  const [isEditing, setIsEditing] = useState(false);
  const [editableGoalName, setEditableGoalName] = useState("");
  const [editableGoalDetails, setEditableGoalDetails] = useState("");
  const [isArchiveConfirmationOpen, setIsArchiveConfirmationOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  useDocumentTitle(goal?.name + " - Lifehack App");

  useEffect(() => {
    if (goal) {
      setEditableGoalName(goal.name || "");
      setEditableGoalDetails(goal.details || "");
    }
  }, [goal]);

  const handleSaveGoal = () => {
    if (!goal) return;
    
    const updatedGoal = {
      ...goal,
      name: editableGoalName,
      details: editableGoalDetails ? editableGoalDetails : "",
    };

    updateGoal.mutate(updatedGoal);
    setIsEditing(false);
  };

  const handleArchiveGoal = () => {
    archiveGoal.mutate(goalId);
    updateSettings({ activeGoal: "" });
    navigate("/");
  };

  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "700px";
    }
  }, [editableGoalDetails]);

  if (isPending || !goal) {
    return <span>Loading...</span>;
  }

  if (isError) {
    return <span>Error: {error.message}</span>;
  }

  return (
    <>
      <div className="flex flex-col mt-4">
        <div className="flex justify-between items-center">
          {isEditing ? (
            <>
              <Input
                className="text-2xl md:text-4xl font-bold max-w-4xl m-0 p-0 border-transparent font-argent bg-yellow-100"
                value={editableGoalName}
                onChange={(e) => setEditableGoalName(e.target.value)}
              />
              <div className="flex justify-end">
                <Button
                  variant="ghost"
                  onClick={() => {
                    setIsEditing(false);
                  }}
                  className="block mr-2 ml-4"
                >
                  Cancel
                </Button>
                <Button onClick={handleSaveGoal}>Save</Button>
              </div>
            </>
          ) : (
            <>
              <h1 className="text-2xl md:text-4xl font-bold p-0 font-argent">{goal.name}</h1>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="ghost" className="px-3 py-2">
                    <MoreHorizontal size={16} />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-56">
                  <DropdownMenuGroup>
                    <DropdownMenuItem
                      onClick={() => {
                        setIsEditing(true);
                      }}
                    >
                      <Pencil className="mr-2 h-4 w-4" />
                      <span>Edit</span>
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      onClick={() => {
                        setIsArchiveConfirmationOpen(true);
                      }}
                    >
                      <Trash className="mr-2 h-4 w-4 text-red-500" />
                      <span className="text-red-500">Archive</span>
                    </DropdownMenuItem>
                  </DropdownMenuGroup>
                </DropdownMenuContent>
              </DropdownMenu>
              <AlertDialog open={isArchiveConfirmationOpen} onOpenChange={setIsArchiveConfirmationOpen}>
                <AlertDialogTrigger asChild></AlertDialogTrigger>
                <AlertDialogContent>
                  <AlertDialogHeader>
                    <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
                    <AlertDialogDescription>
                      This action cannot be undone. This will permanently archive your goal and its projects.
                    </AlertDialogDescription>
                  </AlertDialogHeader>
                  <AlertDialogFooter>
                    <AlertDialogCancel>Cancel</AlertDialogCancel>
                    <AlertDialogAction onClick={handleArchiveGoal}>Continue</AlertDialogAction>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>
            </>
          )}
        </div>

        {isEditing ? (
          <Textarea
            ref={textAreaRef}
            className="mt-4 p-0 border-transparent bg-yellow-100 text-sm md:text-base max-w-4xl"
            style={{ overflow: "hidden", resize: "none" }}
            value={editableGoalDetails}
            onChange={(e) => setEditableGoalDetails(e.target.value)}
          />
        ) : (
          <div>
            {goal.linkedPromptId && (
              <LinkedPrompt
                name={goal.linkedPromptName || "LifeHack 360 Assessment"}
                href={`/prompts/${goal.linkedPromptId}`}
              />
            )}
            <div className="text-sm md:text-base max-w-4xl mt-4 p-0 leading-normal">
              {goal.details && (
                <>
                  <Markdown className={cn(
                    "md-container",
                    !isExpanded && "!text-slate-400"
                  )}>
                    {isExpanded ? goal.details : goal.details.split('\n').slice(0, 2).join('\n')}
                  </Markdown>
                  {goal.details.split('\n').length > 3 && (
                    <Button
                      variant="link"
                      onClick={() => setIsExpanded(!isExpanded)}
                      className="mt-2 p-0"
                    >
                      {isExpanded ? "Read less" : "Read more"}
                    </Button>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default GoalSection;
