import { Link } from "react-router-dom";
import { useFetchGoalByGoalId } from "@/queries/goals";
import { useAuthContext } from "@/providers/AuthProvider";
import { useFetchUserById } from "@/queries/user";
import { Plus, ListRestart } from "lucide-react";
import { useFetchAllPromptAnswers } from "@/queries/promptAnswers";
import { useUpdateUserMutation } from "@/queries/user";
import { useChatContext } from "@/web/hooks/useChatContext";
import { motion, AnimatePresence } from "framer-motion";
import {
  FloatingActionPanelRoot,
  FloatingActionPanelTrigger,
  FloatingActionPanelContent,
  FloatingActionPanelButton,
} from "@/web/components/ui/floating-action-panel";

function BigNorthstarGoalItem({ goalId, onClose }: { goalId: string; onClose: () => void }) {
  const { data: goal } = useFetchGoalByGoalId(goalId);
  const updateUser = useUpdateUserMutation();
  const { setGoalId } = useChatContext();
  
  if (!goal) return null;

  const handleClick = () => {
    updateUser.mutate({ bigGoalId: goalId }, {
      onSuccess: () => {
        setGoalId(goalId);
        onClose();
      }
    });
  };

  return (
    <FloatingActionPanelButton onClick={handleClick}>
      <div className="text-left w-full">
        {goal.name}
      </div>
    </FloatingActionPanelButton>
  );
}

function BigNorthstarActions() {
  const { user: currentUser } = useAuthContext();
  const { data: user } = useFetchUserById(currentUser?.uid || "");
  const { data: promptAnswers } = useFetchAllPromptAnswers();
  const bigNorthstarAnswers = promptAnswers?.filter(
    answer => answer.goalId && 
    answer.goalId !== user?.bigGoalId
  ) || [];

  return (
    <FloatingActionPanelRoot>
      {({ closePanel }) => (
        <>
          <FloatingActionPanelTrigger title="Change Big Northstar" mode="actions" className="flex items-center gap-1.5 text-xs text-zinc-500 hover:text-zinc-900">
            <ListRestart className="w-4 h-4" />
            <span>Change</span>
          </FloatingActionPanelTrigger>

          <FloatingActionPanelContent>
            <div className="space-y-1 p-2">
              <FloatingActionPanelButton>
                <Link to="/create-big-northstar" className="text-left w-full">
                  <div className="flex items-center gap-2">
                    <Plus className="h-4 w-4 shrink-0" />
                    <span>Start New Big Northstar</span>
                  </div>
                </Link>
              </FloatingActionPanelButton>

              {bigNorthstarAnswers.length > 0 && (
                <>
                  {bigNorthstarAnswers.map((answer) => (
                    answer.goalId && <BigNorthstarGoalItem key={answer.id} goalId={answer.goalId} onClose={closePanel} />
                  ))}
                  <div className="px-2 text-xs text-zinc-500">
                    Only the northstar goal created through the LifeHack 360 Assessment will be shown here.
                  </div>
                </>
              )}
            </div>
          </FloatingActionPanelContent>
        </>
      )}
    </FloatingActionPanelRoot>
  );
}

export function BigNorthStarTitle() {
  const { user: currentUser } = useAuthContext();
  const { data: user } = useFetchUserById(currentUser?.uid || "");
  const { data: goal } = useFetchGoalByGoalId(user?.bigGoalId || "");

  if (!user?.bigGoalId) return null;

  return (
    <div className="space-y-2 mt-10">
      <div className="flex items-center justify-between">
        <h3 className="text-primary font-semibold">Your Big Northstar</h3>
        <BigNorthstarActions />
      </div>
      <AnimatePresence mode="wait">
        {goal ? (
          <motion.h2
            key={goal.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.4, ease: "easeOut" }}
            className="font-argent font-bold text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-gray-800 leading-tight break-words hyphens-auto"
          >
            {goal.name}
          </motion.h2>
        ) : (
          <div className="space-y-2">
            <div className="h-2 sm:h-10 md:h-12 lg:h-4 bg-gray-200 rounded-lg animate-pulse w-3/4" />
            <div className="h-2 sm:h-10 md:h-12 lg:h-4 bg-gray-200 rounded-lg animate-pulse w-1/2" />
          </div>
        )}
      </AnimatePresence>
    </div>
  );
}
